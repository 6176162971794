<template>
  <v-container>
    <h2 class="primary--text">Récapitulatif financier de l'activité</h2>
    <v-simple-table height="87vh" fixed-header class="p-relative" dense>
      <template v-slot:default>
        <thead>
        <tr>
          <td class="primary--text" colspan="5">
            <v-icon color="primary">mdi-filter</v-icon> Filtres
          </td>
          <td>
            <v-btn @click="showRealise = !showRealise" color="primary" icon>
              <v-icon v-if="showRealise">mdi-minus</v-icon>
              <v-icon v-else>mdi-plus</v-icon>
            </v-btn>
          </td>
          <td :colspan="showRealise ? 20 : 3"></td>
          <td>
            <v-btn @click="showBudget = !showBudget" color="primary" icon>
              <v-icon v-if="showBudget">mdi-minus</v-icon>
              <v-icon v-else>mdi-plus</v-icon>
            </v-btn>
          </td>
        </tr>
        <tr>
          <td>
            <v-select label="Années" :items="active_years" small-chips multiple v-model="filters.annees"></v-select>
          </td>
          <td style="min-width: 100px">
            <v-autocomplete small-chips :items="dossiers" item-text="fullname" item-value="id" multiple label="Noms" v-model="filters.noms"></v-autocomplete>
          </td>
          <td style="min-width: 100px">

          </td>
          <td>
            <v-select label="Statuts" :items="statuts" item-text="name" item-value="id" multiple small-chips v-model="filters.statuts"></v-select>
          </td>

          <td>
            <v-btn color="red" @click="cleanFilters" text><v-icon>mdi-close</v-icon>Effacer</v-btn>
          </td>
          <td :colspan="showRealise ? 17 : 2" class="grey darken-2 text-center text-uppercase white--text body-1">
            RÉALISÉ
          </td>
          <td colspan="2" rowspan="2" class="grey darken-2 text-center text-uppercase white--text body-1">Écart (Réalisé
            - Budget)
          </td>
          <td :colspan="showBudget ?15 : 2" class="grey darken-2 text-center text-uppercase white--text body-1">BUDGET
          </td>
        </tr>
        <tr>
          <td colspan="5"></td>
          <td :colspan="showRealise ? 8 : 1" class="primary text-center text-uppercase white--text body-1">
            Encaissements
          </td>
          <td :colspan="showRealise ? 9 : 1" class="secondary text-center text-uppercase white--text body-1">
            Décaissements
          </td>

          <td :colspan="showBudget ? 11 : 1" class="primary text-center text-uppercase white--text body-1">
            Encaissements
          </td>
          <td :colspan="showBudget ? 4 : 1" class="secondary text-center text-uppercase white--text body-1">
            Décaissements
          </td>

        </tr>
        <tr>
          <th class="sticky-col s-1"  style="min-width: 100px">Numéro d'arrêté</th>
          <th class="sticky-col s-2">Nom</th>
          <th class="sticky-col s-3">Prénom</th>
          <th class="sticky-col s-4" style="min-width: 200px">Statut</th>
          <th>Flux de trésorerie</th>
          <th :class="showRealise ? '' : 'd-none'" :key="type" v-for="type in typesEncaissement">{{ type }}</th>
          <th :class="showRealise ? '' : 'd-none'" :key="typeD" v-for="typeD in typesDecaissement">{{ typeD }}</th>

          <!--
          <td v-if="showRealise">DEAL - AISFT Prévisionnel</td>
          <td v-if="showRealise">DEAL - AISFT Arrêté</td>
          <td v-if="showRealise">DEAL - AISFT Travaux</td>
          <td v-if="showRealise">DEAL - LBU MOE</td>
          <td v-if="showRealise">DEAL - LBU Travaux</td>
          <td v-if="showRealise">DEAL - LBU Complémentaire</td>
          <td v-if="showRealise">CTM - SUBVENTION</td>
          <td v-if="showRealise">BENEFICIAIRE - APPORT</td>
          <td v-if="showRealise">ESPACE SUD</td>

          <td v-if="showRealise">RECRUTEMENT BENEFICIAIRE</td>
          <td v-if="showRealise">ETUDE TECHNIQUE</td>
          <td v-if="showRealise">DIAGNOSTICS (BET)</td>
          <td v-if="showRealise">DECLARATION PREALABLE</td>
          <td v-if="showRealise">HONORAIRES CONDUCTEUR DE TRAVAUX</td>
          <td v-if="showRealise">TRAVAUX DEMARRAGE</td>
          <td v-if="showRealise">TRAVAUX TRANCHE 1</td>
          <td v-if="showRealise">TRAVAUX TRANCHE 2</td>
          <td v-if="showRealise">TRAVAUX SOLDE</td>
          <td v-if="!showRealise">Total encaissements</td>
          <td v-if="!showRealise">Total décaissements</td>-->

          <th class="primary text-center text-uppercase white--text body-1">Encaissements</th>
          <th class="secondary text-center text-uppercase white--text body-1">Décaissements</th>
          <th v-if="showBudget">DEAL - AISFT</th>
          <th v-if="showBudget">DEAL - MOE</th>
          <th v-if="showBudget">DEAL - SUBVENTION Travaux</th>
          <th v-if="showBudget">DEAL - SUBVENTION Complémentaire</th>
          <th v-if="showBudget">CTM - SUBVENTION</th>
          <th v-if="showBudget">APPORT PERSONNEL - ESPACE SUD</th>
          <th v-if="showBudget">SUBVENTION CAF</th>
          <th v-if="showBudget">PRÊT CAF</th>
          <th v-if="showBudget">PRÊT MARTINIQUE HABITAT</th>
          <th v-if="showBudget">AUTRES PRÊT</th>
          <th v-if="showBudget">APPORT PERSONNEL</th>

          <th v-if="showBudget">TRAVAUX</th>
          <th v-if="showBudget">MOE</th>
          <th v-if="showBudget">FRAIS MARTINIQUE HABITAT</th>
          <th v-if="showBudget">AISFT</th>

          <th v-if="!showBudget">Total encaissements</th>
          <th v-if="!showBudget">Total décaissements</th>

          <th>CHECK BUDGET EQUILIBRE</th>
          <th>NUMERO DOSSIER</th>
          <th>MILLESIME ANNEE ARRETE</th>
          <th>Check</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="loading">
          <td :colspan="showRealise ? 17 : 15" class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </td>
        </tr>
        <tr v-for="dossier in filtered_dossiers" :key="dossier.id">
          <td class="sticky-col s-1">{{ dossier.numero_arrete }}</td>
          <td class="sticky-col s-2"><h4 class="primary--text">
                  <router-link class="text-decoration-none" :to="`/finances/${dossier.id}`">{{
                      dossier.beneficiaire_last_name
                    }}
                  </router-link>
                </h4></td>
          <td class="sticky-col s-3"><h4 class="primary--text">
                  <router-link class="text-decoration-none" :to="`/finances/${dossier.id}`">{{
                      dossier.beneficiaire_first_name
                    }}
                  </router-link>
                </h4></td>
          <td class="sticky-col s-4">{{ dossier.current_statut }}</td>
          <td :class="dossier.flux_tresorerie >= 0 ? 'primary white--text' : 'secondary white--text'">{{ dossier.flux_tresorerie.toFixed(2) }} €</td>
          <!-- ENCAISSEMENTS : 10 -->
          <td :class="showRealise ? '' : 'd-none'" :key="type" v-for="type in typesEncaissement">{{ find_saisie(dossier.saisies, type) }}</td>
          <td :class="showRealise ? '' : 'd-none'" :key="typeD" v-for="typeD in typesDecaissement">{{ find_saisie(dossier.saisies, typeD) }}</td>
          <!-- DECAISSEMENTS -->
          <!-- TOTAUX -->
          <td v-if="!showRealise">{{ dossier.total_encaissements.toFixed(2) }} € - TOTAL ENCAISSEMENTS</td>
          <td v-if="!showRealise">{{ dossier.total_decaissements.toFixed(2) }} € - TOTAL DECAISSEMENTS</td>
          <!-- ECARTS -->
          <td>{{ dossier.ecart_encaissements.toFixed(2) }} €</td>
          <td>{{ dossier.ecart_decaissements.toFixed(2) }} €</td>

          <td v-if="showBudget">{{ dossier.aisft.toFixed(2) }} €</td>
          <td v-if="showBudget">{{ dossier.maitrise_oeuvre.toFixed(2) }} €</td>
          <td v-if="showBudget">{{ dossier.subvention_etat_travaux.toFixed(2) }} €</td>
          <td v-if="showBudget">{{ find_financement(dossier, "DEAL - SUBVENTION Complémentaire") }}</td>
          <td v-if="showBudget">{{ find_financement(dossier, "subvention ctm") }}</td>
          <td v-if="showBudget">{{ find_financement(dossier, "subvention espace sud")}}</td>
          <td v-if="showBudget">{{ find_financement(dossier, "subvention caf") }}</td>
          <td v-if="showBudget">{{ find_financement(dossier, "prêt caf") }}</td>
          <td v-if="showBudget">{{ find_financement(dossier, "prêt martinique habitat") }}</td>
          <td v-if="showBudget">{{ find_financement(dossier, "AUTRES PRÊT") }}</td>
          <td v-if="showBudget">{{ find_financement(dossier, "apport personnel") }}</td>
          <td v-if="showBudget">{{ dossier.montant_travaux.toFixed(2) }} €</td>
          <td v-if="showBudget">{{ dossier.maitrise_oeuvre.toFixed(2) }} €</td>
          <td v-if="showBudget">{{ find_honoraire(dossier, "frais du prêt martinique habitat") }}</td>
          <td v-if="showBudget">{{ find_honoraire(dossier, "mission aisft") }}</td>

          <td v-if="!showBudget">{{ dossier.total_ressources.toFixed(2) }} €</td>
          <td v-if="!showBudget">{{ dossier.total_emplois.toFixed(2) }} €</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>

import {TYPES_DECAISSEMENT, TYPES_ENCAISSEMENT} from "@/constants/Financement";
import dayjs from "dayjs";

export default {
  name: "Financier",
  async mounted() {
    this.loading = true;
    this.dossierFinanceInfos = await this.$store.dispatch("dossier/getAllDossierFinanceData");
    this.loading = false;
    let statusFetchStatus = this.$store.getters["dossier/statuts/statut_fetch_status"];
    if (statusFetchStatus === "INITIAL" || statusFetchStatus === "ERROR") {
      await this.$store.dispatch("dossier/statuts/getStatuts");
    }

  },
  data() {
    return {
      showRealise: true,
      showBudget: true,
      loading: true,
      dossierFinanceInfos: [],
      filters: {
        annees: [],
        noms: [],
        statuts: [],
      }
    }
  },
  computed: {
    typesEncaissement() {
      return TYPES_ENCAISSEMENT;
    },
    typesDecaissement() {
      return TYPES_DECAISSEMENT;
    },
    dossiers() {
      return this.dossierFinanceInfos.map(function (dossier) {
        return {...dossier, fullname: dossier.beneficiaire_last_name + " " + dossier.beneficiaire_first_name};
      })
    },
    filtered_dossiers() {
      return this.dossierFinanceInfos
          .filter(dossier => this.filters.noms.length > 0 ? this.filters.noms.includes(dossier.id) :  true)
          .filter(dossier => this.filters.statuts.length > 0 ? this.filters.statuts.includes(dossier.statut_id) : true)
          .filter(dossier => this.filters.annees.length > 0 ? this.filters.annees.includes(dayjs(dossier.date_arrete).year()) : true);
    },
    statuts() {
      return this.$store.getters["dossier/statuts/statuts"];
    },
    active_years() {
      let years = this.dossierFinanceInfos ? this.dossierFinanceInfos.map(d => d.date_arrete ? dayjs(d.date_arrete).year() : 2020) : [2023];
      let activeYears = [];
      for(var i = 2019; i < Math.max(...years) + 1; i++) {
        activeYears.push(i);
      }
      return activeYears;
    },
  },
  methods: {
    find_saisie(saisies, source) {
      let saisie = saisies?.find(saisie => saisie.source === source);
      return saisie ? saisie.total.toFixed(2).toString() + " €" : "Non saisie";
    },
    find_honoraire(dossier, source) {
      let honoraire = dossier.honoraires?.find(honoraire => honoraire.origine.toLowerCase().indexOf(source) !== -1);
      return honoraire ? honoraire.montant.toString() + " €" : "Non saisie";
    },
    find_financement(dossier, source) {
      let financement = dossier.financements?.find(financement => financement.origine.toLowerCase().indexOf(source) !== -1 );
      return financement ? financement.montant.toString() + " €" : "Non saisie";
    },
    cleanFilters() {
      this.filters = {
        annees: [],
        noms: [],
        statuts: [],
      };
    }
  }

}
</script>

<style scoped>
.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background: white;
}
.s-1 {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: 0;
  top: 0;
}
.s-2 {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: 100px;
  top: 0;
}
.s-3 {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: 200px;
}
.s-4 {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  left: 300px;
}
.s-5 {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: 500px;
}
.p-relative {
  position: relative;
}
</style>